import { template as template_fa09681b545c4d2ca6bd7bea990c3d96 } from "@ember/template-compiler";
const FKControlMenuContainer = template_fa09681b545c4d2ca6bd7bea990c3d96(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
